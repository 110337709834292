.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
}


.form{
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5em;
    position: relative;
}
.form > h1 {
    color: #fff;
    font-weight: 700;
    font-size: 2em;
    margin:0;
}
.form input[type="text"], .form >input[type="password"], .form >input[type="email"] {
    box-sizing: border-box;
    align-self: stretch;
    background-color: #fff;
    color: #3c3c3b;
    border-radius: 2em;
    font-size: 1.2em;
    font-weight: 500;
    padding: .8em 2em;
    line-height: 1.2em;
}
.form input::placeholder {
    font-size: 1em;
    color: #3c3c3b;
    font-weight: 700;
}

.signInButton{
    background: #3c3c3b;
    color: #fff;
    font-size: 1.8em;
    padding: .5em 1.5em;
    border-radius: 2em;
    box-shadow: none;
    font-weight: 500;
    margin-top: 1em;
}
.returnButton{
    font-size: 1.8em;
    padding: .5em 1.5em;
    font-weight: 500;
    border-radius: 2em;
    box-shadow: none;
    margin-top: 1em;
}
.forgotPasswordButton{
    font-size: 1.5em;
    color: #fff;

}