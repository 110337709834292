.containerParent {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
	height: calc(99vh - var(--navbar-height));
    box-sizing: border-box;
    padding-top: 20px;
}

.container {
    width: 40vw;
    margin: auto;   
}

.container > h1 {
    display: inline-block;
}

.svg {
    width: 4vw;
    height: 4vw;
}

.info {
    margin-top: 10vh;
    margin-bottom: 10vh;
}