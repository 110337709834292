.iconButton {
	box-shadow: none;
}

.iconButton > img {
	height: 3rem;
	width: 3rem;
	cursor: pointer;
}

.header {
	display: flex;
	justify-content: space-between;
	margin: 8px 16px;
	height: 5rem;
}

.container {
	display: flex;
	flex-direction: column;
	overflow: hidden;
	height: var(--full-mobile-page-height);
}

.productsContainer {
	flex-grow: 1;
	overflow: auto;
	padding: 1em 0;
}

.titleContainer {
	text-align: center;
}

.productContainer {
	background: #ededed;
	margin: 8px 24px;
	border-radius: 4px;
	display: flex;
	position: relative;
	align-items: center;
}

.productContainer > img {
	height: 6rem;
	max-width: 300px;
	aspect-ratio: 4 / 5;
	object-fit: cover;
	border-radius: 4px;
	margin: 4px;
}

.productInfo {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin: 4px;
	width: 100%;
}

.productAddToCartContainer {
	position: absolute;
	bottom: 0;
	right: 0;
}

.productCartControls {
	width: fit-content;
	display: flex;
	align-items: center;
	margin-bottom: 8px;
	margin-right: 8px;
	border-radius: 4px;
	background: #fff;
}

.productAvailabilityContainer {
	background: #f39200;
	color: #fff;
	border-radius: 4px;
	padding: 2px 4px;
	font-size: 0.8em;
	font-weight: 500;
	text-align: right;
	align-self: flex-start;
	text-wrap: nowrap;
}

.productDesc {
	margin-bottom: 12px;
}

.productTitle {
	display: flex;
	justify-content: space-between;
	margin-bottom: 4px;
}

.productCounter {
	background: #f39200;
	color: #fff;
	position: absolute;
	height: 1.5rem;
	width: 1.5rem;
	border-radius: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
	bottom: 32px;
	left: 12px;
}

.productInKioskContainer {
	background: #ff4f4f;
	color: #fff;
	border-radius: 4px;
	padding: 2px 4px;
	font-size: 0.8em;
	font-weight: 500;
	text-align: right;
}

.changeDateButton {
	margin-top: 4px;
	background: var(--primary);
	white-space: nowrap;
	color: #fff;
	font-weight: 600;
	font-size: 1.1em;
}
