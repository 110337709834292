.mapContainer {
	height: var(--full-mobile-page-height);
}

.titleContainer {
	z-index: 1000;
	position: fixed;
	top: 4px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	user-select: none;
	pointer-events: none;
}

.titleContainer > span {
	background-color: var(--primary);
	color: white;
	font-size: 1.5rem;
	padding: 8px;
	border-radius: 4px;
	position: relative;
	transition: 0.5s;
}

.topBar {
	position: fixed;
	z-index: 500;
	background: white;
	width: 100vw;
	height: 2px;
	box-shadow: 0px 2px 5px 2px white;
}
