.mapContainer {
	height: var(--full-page-height);
}

.titleContainer {
	z-index: 1000;
	position: fixed;
	top: 0;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	user-select: none;
	pointer-events: none;
}

.titleContainer > span {
	background-color: var(--primary);
	color: white;
	font-size: 1.5rem;
	margin-top: 4px;
	padding: 8px;
	border-radius: 8px;
	position: relative;
	transition: 0.5s;
}

.productsButton[name="active"],
.cartButton[name="active"] {
	color: white;
	background-color: var(--primary);
}

.productsButton {
	top: 6px;
	left: 16px;
}

.cartButton {
	top: 6px;
	right: 16px;
}

.productsButton,
.cartButton {
	z-index: 1000;
	position: fixed;
	padding: 6px;
	width: 3rem;
	height: 3rem;
}

.productsContainer {
	max-width: 100vw;
	margin-top: 64px;
	display: grid;
	gap: 16px 0;
	grid-template-columns: repeat(auto-fit, minmax(min(100%/2, max(200px, 100%/6)), 1fr));
	align-content: center;
	align-items: center;
	height: min-content;
	padding-bottom: var(--navbar-height);
}

.productsContainer > div {
	background-color: white;
	box-shadow: 0px 0px 2px 0px gray;
	border-radius: 8px;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin: 10px;
}

.productAddToCart {
	color: var(--font-color);
	display: flex;
	padding: 0 8px 4px 8px;
	align-items: center;
	justify-content: space-evenly;
}

.productAddToCart > input {
	box-shadow: none;
	border: 1px solid #c8c8c8;
	padding: 8px;
	width: 20px;
	border-radius: 8px;
}

.productsContainer span[name="cost"] {
	color: var(--primary);
	font-weight: bold;
	padding-top: 8px;
}

.productsContainer img {
	width: 100%;
	max-width: 300px;
	aspect-ratio: 3 / 2;
	object-fit: cover;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
}

.productsContainer > img {
	pointer-events: none;
	user-select: none;
}

.productInfo {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
}

.cartContainer {
	padding: 20px;
}

.productItem {
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: space-between;
	border-bottom: 1px solid #c8c8c8;
}

.productItem:last-child {
	border-bottom: unset;
}

.productItem input[type="number"] {
	box-shadow: none;
	border: 1px solid #c8c8c8;
	padding: 8px;
	width: 40px;
	border-radius: 8px;
}

img.productPhoto {
	height: 3rem;
	width: 3rem;
	margin-right: 10px;
}

.productCost {
	display: flex;
	flex-direction: row;
	width: 150px;
	align-items: center;
	justify-content: space-between;
}

.productCost > button {
	appearance: none;
	border: none;
	font-size: 1em;
	width: 32px;
	height: 32px;
	background-color: unset;
}

.productName {
	display: flex;
	align-items: center;
}

.productHeaderText {
	width: 120px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.bottomContainer > p {
	font-size: 2rem;
	color: var(--primary);
	font-weight: bold;
}

.bottomContainer {
	margin-top: 20px;
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: center;
}

.bottomContainer > button {
	box-shadow: none;
	background: var(--primary);
	color: white;
	font-size: 1.5rem;
	padding: 10px 40px;
}

.deliveryTime {
	margin-top: 32px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.deliveryTime fieldset {
	border: 0;
}

.deliveryTime button {
	display: flex;
	flex-direction: column;
	align-items: center;
	background: var(--primary);
	color: white;
	font-size: 1rem;
	font-weight: bold;
	padding: 10px 20px;
	border-radius: 1.5em;
	color-scheme: dark;
}

.deliveryTime input {
	padding: 0;
	height: 0;
}

.categoriesPage {
	position: sticky;
	top: 0px;
	display: none;
}

@media (min-width: 700px) {
	.mainContainer {
		display: flex;
		margin-top: 64px;
	}

	.productsButton[name="categories"] {
		display: none;
	}

	.productsContainer {
		margin: 0;
		padding-left: 20px;
		max-width: 75vw;
	}

	.categoriesPage {
		padding-left: 30px;
		display: block;
		height: var(--full-page-height);
	}
}

.availableOnDevice {
	background-color: #ff4f4f;
	color: #fff;
	padding: 0 3em;
	top: 1.7em;
	right: -3em;
	font-size: 0.9em;
	position: absolute;
	text-transform: uppercase;
	text-align: center;
	font-weight: bold;
	transform: rotate(45deg);
}

.availableOnFuture {
	background-color: #ff954f;
	color: #fff;
	padding: 0 3em;
	top: 1.7em;
	left: -3em;
	font-size: 0.9em;
	position: absolute;
	text-transform: uppercase;
	text-align: center;
	font-weight: bold;
	transform: rotate(-45deg);
}

.container fieldset {
	border: none;
	padding: 0;
	position: relative;
}

.container fieldset legend {
	background-color: white;
	border: 1px solid black;
	border-radius: 4px;
	padding: 2px 4px;
	position: absolute;
	color: black;
	top: -10px;
	left: 15px;
}

.container textarea {
	resize: vertical;
	width: 600px;
	max-width: calc(100vw - 4.2em - 15px);
	font-family: inherit;
	margin-bottom: 10px;
	border-top: 1px solid gray;
}

/* nowe */
.container > h1 {
	text-align: center;
	margin-bottom: 0;
}

.productContainer {
	background: #ededed;
	position: relative;
	border-radius: 8px;
}

.productContainer input {
	margin-left: 8px;
	margin-bottom: 8px;
	border-radius: 8px;
}

.productInfoContainer {
	height: 6rem;
	margin: 8px 0;
	border-radius: 1em;
	display: flex;
}

.productInfoContainer > img {
	height: calc(100% - 8px);
	max-width: 300px;
	aspect-ratio: 4 / 5;
	object-fit: cover;
	border-radius: 8px;
	margin: 4px;
}

.productInfo {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin: 4px;
}

.productAddToCartContainer {
	position: absolute;
	top: 8px;
	right: 0;
}

.productCartRemove {
	position: absolute;
	bottom: 2px;
	right: 8px;
}

.productCartControls {
	width: fit-content;
	display: flex;
	align-items: center;
	margin-bottom: 14px;
	margin-right: 8px;
	border-radius: 8px;
	background: #fff;
}

.emptyCartMessage {
	text-align: center;
	width: 100%;
}
/* nowe */
.shopClosedContainer{
	text-align: center;
	display: flex;
	align-items: center;
	flex-direction: column;
}

.dateCalendarOpen{
    display: flex !important;
}
.dateCalendarClosed{
    display: none !important;
}
.dateCalendar {
    position: absolute;
    top: 30%;
    /* left: -35%; */
    margin: auto;
    margin-right: auto;
    background-color: #3c3c3b;
    color: #fff;
    z-index: 100;
    border-radius: 2em;
}
.dateCalendar button, .dateCalendar span {
    color: #fff;
	background: none;
}
.dateCalendar button:disabled{
    color: #a19f9f !important;
}
.dateCalendar button:hover{
    box-shadow: none;
}
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
	background-color: rgba(0,0,0,0.5);
}