.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: calc(99vh - var(--navbar-height));
}
.content {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 2em;;
	gap: 7em;
	gap: 7em;
}
.mapContainer{
	margin-top: 8em;
	border-radius: 2em;
}
.map {
	width:50vw;
	border-radius: 2em;
	height:55vh;
}
.pickupPointsListContainer{
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	color:#fff;
	width: 30vw;
	/* padding: 2em 0; */
}
.searchBar{
	position: relative;
}
.searchBar > svg{
	position: absolute;
	top: 1.2em;
	left: 2em;
	height:50%;
}
.searchBar > input {
	box-sizing: border-box;
	background-color: #3c3c3b;
	color: #fff;
	font-size: 1.8em;
	padding: .8em;
	padding-left: 5em;
	border-radius: 2em;
	width: 100%;
}
.searchBar > input::placeholder {
	color: #fff;
	font-size: 1em;
	font-weight: 500;
}
.searchBar > input:hover {
	outline: none;
}
.pickupPointsList{
	box-sizing: border-box;
	display: flex;
	margin-top: 2.5em;
	overflow: hidden;
	background-color: #3c3c3b;
	flex-direction: column;
	border-radius: 2em;
	height: 55vh;
	padding: 2em 0;
}

.pickupPointsListContent{
	height:100%;
	display: flex;
	flex-direction: column;
	overflow: auto;
}

.pickupPoint {
	display: flex;
	padding: .3em 2em;
	font-size: 1.5em;
	font-weight: 500;
	justify-content: space-between;
	align-items: center;
}
.pickupPoint > p {
	width: 100%;
}
.pickupPoint:hover{
	background-color: #2c2c2b;
	cursor: pointer;
}
.pickupPoint > button {
	flex-grow: 1;
	color: #fff;
	box-shadow: none;
	border-radius: 2em;
	padding:  0 1em;
	font-size: 1em;
	white-space: no-wrap;
	width: 15em;
	font-weight: 700;
	height: 2em;
	background-color: var(--primary);
}

@media (max-width: 1400px) {
	.container{
        font-size: .65em;
    }
} 
