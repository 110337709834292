
.input {
	position: relative;
}
.input input {
    background: none;
    font-size: 2em;
    padding:0;
    text-align: center;
    border:none;
}
.input input:focus {
   outline: none;
}
.input input::placeholder {
   font-weight: 700;
   text-align: center;
   color: #3c3c3b;
}

.input::after {
    content: "";
    display: block;
    width: 100%;
    height: 0.5em;
    bottom: -.5em;
    left:0;
    position: absolute;
    background-color:var(--primary);
    clip-path: polygon(0 50%, 60% 100%, 100% 60%, 75% 0);
}