.container {
	background: #3c3c3b;
	color: #fff;
	border-radius: 1em;
	padding: 32px 16px 32px 40px;
	height: 100%;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	overflow: hidden;
	min-width: 20vw;
	max-width: 30vw;
}

.container div[name="category"],
.container div[name="categoryTitle"] {
	border-bottom: 1px solid #c8c8c8;
	padding: 10px;
	text-align: center;
	font-size: 1.2rem;
	transition: 0.5s;
}

.container div[name="categoryTitle"] {
	cursor: pointer;
	border-top: 1px solid black;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	text-align: left;
	text-wrap: nowrap;
	transition: 0.5s;
}

.container div[name="categoryTitle"]:hover {
	background: #6e6e6e;
}

.container div[name="category"]:hover {
	cursor: pointer;
	background: #6e6e6e;
}

.container div[name="category"]:last-child {
	border-bottom: unset;
}

.container b {
	margin-left: 8px;
}

.container img {
	border-radius: 4px;
}

.listContainer {
	overflow-y: scroll;
	padding-right: 16px;
}
