.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
    overflow: auto;
    margin-top: var(--navbar-height);
}

.container > h1 {
	color: #fff;
	font-weight: 700;
	font-size: 3em;
}
.form{
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
    position: relative;
}
.form input[type="text"], .form >input[type="password"], .form >input[type="email"] {
    box-sizing: border-box;
    align-self: stretch;
    background-color: #fff;
    color: #3c3c3b;
    border-radius: 2em;
    font-size: 1.2em;
    font-weight: 500;
    padding: .8em 2em;
    line-height: 1.2em;
}
.form input::placeholder {
    font-size: 1em;
    color: #3c3c3b;
    font-weight: 700;
}
.form input[type="checkbox"] {
    display: inline-block;
    width:1.7em;
    height:1.7em;
}
.form > fieldset {
    display: flex;
    align-items: center;
    border: none;
    background-color: #fff;
    border-radius: 2em;
    font-weight: 700;
    padding:.5em 2em;
    gap: .5em;
}
.form > fieldset a{
    color:#3c3c3b;
    text-decoration: none;
}
.form > fieldset a:hover{
    text-decoration: underline;
}
.signUpButton{
    background: #3c3c3b;
    color: #fff;
    font-size: 1.8em;
    padding: .5em 1.5em;
    border-radius: 2em;
    box-shadow: none;
    font-weight: 500;
    margin-top: 1em;
}
.returnButton{
    font-size: 1.8em;
    padding: .5em 1.5em;
    font-weight: 500;
    border-radius: 2em;
    box-shadow: none;
    margin-top: 1em;
}
.passwordCheckContainer {
    
    position: absolute;
    right: -80%;
    top:0;
}
.passwordCheckContainer > h2{
    color: #fff;
    font-weight: normal;
    text-decoration: underline;
    font-size: 1.5em;
    margin: 0;
}
.passwordCheckContainer  ul{
    padding-left: 20px;
}
.passwordCheckContainer li > h2{
    font-weight: normal;
    color: #fff;
    margin:0;
    font-size: 1.2em;
}
.error {
    font-weight: 700;
    color:red;
}