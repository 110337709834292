.calendar {
    background-color: #F2F2F2;
    margin-top: 50px;
}

.noticePrompt {
    margin-top: 50px;
    font-size: 20pt;
}

.roundedButton {
    border-radius: 100px;
    padding: 20px 60px;
    font-size: larger;

}