.container {
	align-items: center;
	padding: 2em;
	align-items: center;

	height: 80%;
}

.container > * {
	margin-bottom: 3em;
}

.container h1 {
	text-align: center;
}

.inputContainer {
	display: flex;
	flex-direction: column;
	width: 80vw;
	margin: auto;
}

.inputContainer > .input,
.buttonContainer > button {
	margin-bottom: 15px;
}

.input {
	border-radius: 50px;
	color: var(--background-accent);
	background-color: var(--font-color);
	margin-top: 15px;
	margin-bottom: 15px;
	padding: 20px;
	padding-left: 30px;
}

.input::placeholder {
	color: #e0e0e0;
	font-size: larger;
}

.buttonContainer {
	display: flex;
	flex-direction: column;
}

.primaryButton {
	font-size: 1.2em;
	background: var(--primary);
	color: white;
}

.payCard {
	max-width: 300px;
	background-size: cover;
	border-radius: 16px;
	box-shadow: 3px 3px 5px #bdbdbd;
	margin-bottom: 4px;
}

.title {
	color: gray;
	text-align: center;
	font-weight: 100;
	font-size: 1.4rem;
}

.linksContainer {
	display: block;
	text-align: center;
}

.linksContainer > p > a,
.linksContainer > p > a:active .linksContainer > p > a:visited {
	color: var(--font-color);
	text-decoration: underline;
}

.linksContainer > p > a:hover {
	color: #0d0d0d;
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
	}

	70% {
		transform: scale(1);
	}

	100% {
		transform: scale(0.95);
	}
}

.editButton {
	cursor: pointer;
	border-radius: 1em;
	color: white;
	position: relative;
	background: var(--primary);
	padding: 20px;

	font-size: 0.9em;
	transition: 0.5s;
	border: none;
	box-shadow: 3px 3px 5px #bdbdbd;
	user-select: none;

	width: 140px;
	margin: auto;
	margin-top: 10px;
	margin-bottom: 60px;
	box-sizing: border-box;

	font-size: larger;

	border-radius: 100px;

	white-space: nowrap;
}

.editButton:disabled {
	color: white;
	background-color: gray;
}

.loadingButton {
	color: black;
	background-color: #ffe07b !important;
	animation: pulse 2s infinite;
}

.loadingButton::before {
	animation: pulse 2s infinite;
}

.container button:hover {
	box-shadow: 3px 3px 5px 2px #bdbdbd;
}

.editButton:active,
.editButton[name="active"] {
	background: var(--success-color) !important;
}

.refreshButton {
	background-color: #ffe07b !important;
	animation: pulse 2s infinite;
}

.errorButton {
	color: black !important;
	background-color: var(--error-color) !important;
}

.notification {
	padding: 4px;
	box-shadow: 3px 3px 5px #bdbdbd;
	pointer-events: none;
	border-radius: 10px;
	background: white;
	transition: 1s;
	opacity: 0;
	position: absolute;
	right: 100px;
	top: -10px;
}

.notification[name="show"] {
	opacity: 1;
}

.dialogue > form {
	margin-top: 40px;
}

.passwordCheckContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-right: 16px;
}

.titles {
	text-align: center;
}

.myAccountNotice {
	margin-top: 40px;
	color: var(--primary);
	font-size: 20pt;
}
