.container {
	color: var(--primary);
	display: flex;
	flex-direction: column;
	padding: 2em;
	align-items: center;
}

.container > * {
	margin-bottom: 3em;
}

.container h1 {
	color: #3c3c3b;
}

.container h1,
.container h2 {
	text-align: center;
}

.buttonContainer > button {
	margin-bottom: 15px;
}

.buttonContainer {
	display: flex;
	flex-direction: column;
}

.primaryButton {
	font-size: 1.2em;
	background: var(--primary);
	color: white;
}

.payCard {
	max-width: 300px;
	background-size: cover;
	border-radius: 16px;
	box-shadow: 3px 3px 5px #bdbdbd;
	margin-bottom: 4px;
}

.title {
	color: gray;
	text-align: center;
	font-weight: 100;
	font-size: 1.4rem;
}
