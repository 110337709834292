.container {
	position: absolute;
	top: 0;
	margin-top: var(--navbar-height);
	width: 100%;
	height: var(--full-page-height);
	overflow: auto;
}

.navigation {
	box-shadow: 0px 2px 10px grey;
	background: var(--primary);
	position: fixed;
	bottom: 0;
	width: 100%;
	display: flex;
	height: var(--navigation-height);
	align-items: center;
	justify-content: space-around;
}

.navigation > button {
	background-color: transparent;
	border: none;
	color: white;
}

.navbarIcon {
	height: 2.5rem;
}

@media (min-width: 750px) {
	.navigation > * {
		display: none;
	}
}
