.container{
    /* padding: 1em 0; */
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(var(--full-page-height) - 5em);
}
.content {
    box-sizing: border-box;
    display: flex;
    width:100%;
    justify-content: space-between;
    padding: 0 10em;
    height:100%;
}
.cartContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:2em;
    text-align: center;
    /* background-color: red; */
}
.infoContainer{
    display: flex;
    text-align: left;
    gap: 6em;
    padding: 1em 0;;
}
.deviceLocation, .deliveryTime {
    text-align: center;
}
.deliveryTime {
    position: relative;
}
.deliveryTime > input {
    position: absolute;
    width:0px;
    padding:0;
    margin:0;
    left: 50%;
    top:0;
}
.deliveryTime > p {
    font-weight: 700;
}
.deviceLocation > h1, .deliveryTime > h1, .deviceLocation > p, .deliveryTime > p {
    margin:0;
}
.deviceLocation > p{
    font-weight: 500;
    font-size: 1.2em;
}
.deliveryTimeButton{
    background-color: var(--primary);
    font-size: 1.2em;
    font-weight: 700;
    color: #fff;
    box-shadow: none;
}
.cart{
    display: flex;
    text-align: left;
    flex-direction: column;
    gap: 1em;
    overflow: auto;
    padding-right: 1em;
    min-width: 50em;
}
.cartItem {
    background-color: #ededed;
    border-radius: 1em;
    display: flex;
    flex-direction: row;
    padding: 1em;
    justify-content: space-between;
}
.cartItemDescriptionContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap:1em;
}
.cartItemImage {
    width: 8em;
    height: 8em;
    border-radius: 1em;
}
.cartItemDescription{
    display: flex;
    gap:1em;
}
.cartItemDescriptionInfo > h1, .cartItemDescriptionInfo > p{
    margin: 0;
}
.cartItemActions{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
}
.cartItemActionsQuantity{
    display: flex;
    background-color: #fff;
    align-items: center;
    border-radius: 1em;
    gap:1em;
}
.cartItemActionsQuantity > p{
    margin: 0;
}
.cartItemActionsQuantity > button{
    background-color: var(--primary);
    color: #fff;
    font-size: 2em;
    line-height: 1em;
    box-sizing: border-box;
    border-radius: .3em;
    box-shadow: none;
    padding: 0;
    width:1.2em;
    text-align: center;
    height:1.2em;
}
.cartItemActionsQuantity > button:hover{
    box-shadow: none;
}
.cartItemActionsRemove{
    cursor: pointer;
    background-color: var(--primary);
    box-sizing: border-box;
    width:3em;
    height:3em;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: .8em;

}
.cartItemActionsRemove > svg{
    width:1.5em;
    height:1.5em;


}
.cartItemDescriptionNoteToOrder{
    width: 70%;
}
.cartItemDescriptionNoteToOrder > input{
    width: 100%;
    padding: .6em 2em;
}
.cartItemDescriptionNoteToOrder > input:focus{
    outline:none;
}
.cartIcon {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}
.cartIconAmount {
    position: absolute;
    background-color: #F39200;
    color: #fff;
    box-sizing: border-box;
    height: 2em;
    width: 2em;
    border-radius: 1em;
    font-size: .9em;
    text-align: center;
    font-weight: 700;
    line-height: 2em;
    z-index: 1;
    top:2em;
    left:.3em;
}
.categoriesPage {
    margin-top: 7rem;
}

.orderButton{
    background-color: var(--primary);
    font-size: 1.5em;
    font-weight: 500;
    color: #fff;
    padding:.8em 2em;
    border-radius: 2em;

}

.summary { 
    display: flex;
    align-items: center;
    align-self: flex-end;
    gap:2em;
}
.summary > p {
    font-size: 2em;
    font-weight: 700;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
}
.shopClosedContainer{
    position: relative;
}
.dateCalendarOpen{
    display: flex !important;
}
.dateCalendarClosed{
    display: none !important;
}
.dateCalendar {
    position: absolute;
    top: 120%;
    /* left: -35%; */
    margin-left: auto;
    margin-right: auto;
    background-color: #3c3c3b;
    color: #fff;
    z-index: 100;
    border-radius: 2em;
}
.dateCalendar button, .dateCalendar span {
    color: #fff;
}
.dateCalendar button:disabled{
    color: #a19f9f !important;
}
.dateCalendar button:hover{
    box-shadow: none;
}

@media (max-width: 1400px) {
	.container{
        font-size: .65em;
    }
} 