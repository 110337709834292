.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.content {
    margin-top:2em;
    background-color: #3b3b3c;
    border-radius: 2em;
    color:white;
    box-sizing: border-box;
    padding: 2em;
}
.content > h1 {
    font-size: 1.8em;
}
.title{
    color: var(--primary);
}
.buttonActions {
    display: flex;
    flex-direction: column;
    gap: 1em;
}
.buttonActions > *{
    box-shadow: none;
    font-weight: 700;
    font-size: 1.2em;
}
.buttonActions > *:hover{
    box-shadow: none;
}
.pickUp {
    color:white;
    background-color: var(--primary);
}