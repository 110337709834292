.container{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(95vh - var(--navbar-height))
}
.content {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    width:100%;
    padding: 0 10em;
    height:100%;
}
.ordersContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
    margin-top:2em;
    overflow: auto;
    padding-right: 1em;
    height: calc(90vh - var(--navbar-height));
    border-radius: 30px;
}
.order{
    background-color: #3c3c3b;
    width: 35em;
    display: grid;
    font-size: 2em;
    padding:1em 3em;
    grid-template-columns: repeat(3, 1fr);
    border-radius: 1em;
    gap:1em;
}
.header{
    font-weight: 700;
    color: #fff;
    margin:0;
}
.value {
    color: var(--primary);
    margin: 0;
}
.button {
    background-color: var(--primary);
    color:white;
    padding:.6em 1em;
    border-radius: .5em;
    box-shadow: none;
    font-weight: 700;
}
.button:hover{
    box-shadow: none;
}
@media (max-width: 1400px) {
	.container{
        font-size: .65em;
    }
} 
