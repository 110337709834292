.container {
	background-color: white;
	width: calc(100vw - 30px);
	max-width: 700px;
	border-top-left-radius: 1em;
	border-top-right-radius: 1em;
	position: fixed;
	z-index: 1000;
	bottom: calc(var(--mobile-navigation-height) + 12px);
	color: gray;
	padding: 5px 15px;
	box-shadow: 0px -2px 5px #bdbdbd;
	left: calc(50vw - 350px - 15px);
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow: auto;
	max-height: calc(100vh - 100px);
}

@media (max-width: 700px) {
	.container {
		left: 0;
	}
}

.container h2 {
	text-align: center;
}

.container h4 {
	text-align: center;
	margin-top: 12px;
	margin-bottom: 4px;
}

.container button {
	margin: 10px 0;
}

.photoDesc {
	margin-top: 10px;
	border-radius: 1em;
	max-width: 100%;
}

.container::after {
	content: " ";
	background-color: lightgray;
	width: 20vw;
	height: 3px;
	position: absolute;
	top: 6px;
	left: calc(50% - 10vw);
}

.shopListItem {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.shopListItem > button {
	color: white;
	box-shadow: none;
	background-color: var(--primary);
	padding: 8px;
}

.shopListItem > div {
	display: flex;
}

.shopListItem > div > img {
	margin: auto;
}

.shopListItem > div > p {
	margin-left: 8px;
}

button.pickUp {
	font-size: 1.5rem;
	color: white;
	padding: 10px 30px;
	background: var(--primary);
}
