.gray {
	background: #3c3c3b;
	color: #fff;
}

.primary {
	background: var(--primary);
	color: #fff;
}

.bold {
	font-weight: bold;
}

.round {
	border-radius: 1em;
}
