.root{
    display: flex;
    align-items: center;
	justify-content: safe center;
	height: calc(99vh - var(--navbar-height));
}

.backgroundItems{
    position: fixed;
    top: var(--navbar-height);
    width:100vw;
    height: 100vh;
    z-index: -1;
}
.backgroundItems > img {
    position: absolute;
}
.content{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
}
.tutorialStep{
    margin-top: 2.5em;
    background: var(--primary);
    font-size: 1.5em;
    font-weight: 500;
    color: #fff;
    width: 19em;
    padding: 1em 3em;
    border-radius: 4em;
    text-align: center;
    position: relative;
}
.tutorialStep:nth-child(odd){
    background: #3c3c3b;
}
.tutorialStep:nth-child(odd)::before{
    background: #3c3c3b;
}
.tutorialStep > img {
    width: 1.5em;
    aspect-ratio: 1;
    top: -1.25em;
    left: calc(50% - 0.75em);
    position: absolute;
}
.tutorialStep::before {
    content: "";
    display: block;
    position: absolute;
    width: 2.5em;
    height: 2.5em;
    background-color: var(--primary);
    border-radius: 2em;
    top: -1.75em;
    left: calc(50% - 1.25em);
}

@media (max-width: 1400px) {
	.content{
        font-size: .65em;
    }
} 
