.container {
	width: 100%;
	overflow: auto;
	flex-grow: 1;
}

.navigation {
	box-shadow: 0px 2px 10px grey;
	background: var(--primary);
	box-sizing: border-box;
	width: 100%;
	display: flex;
	height: var(--mobile-navigation-height);
	align-items: center;
	justify-content: space-around;
	padding-top: 4px;
	padding-bottom: 12px;
}

.navigation > button {
	background-color: transparent;
	border: none;
	color: var(--font-color);
	font-size: 0.9em;
	font-weight: 500;
}

.navbarIcon {
	height: 3rem;
}

/* @media (min-width: 750px) {
	.navigation {
		display: none;
	}
} */
