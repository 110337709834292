.container {
	overflow: hidden auto;
	scrollbar-width: none;
	box-sizing: content-box;
	padding: 0 16px;
	display: flex;
	flex-direction: column;
	align-items: center;
	z-index: 2;
	width:100%;
	font-size: 1.9vh;
}

.container::-webkit-scrollbar {
	display: none;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
}

.container > h1 {
	color: #fff;
	font-weight: 700;
	font-size: 2em;
}
.form{
    width: 65%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .5em;
    position: relative;
}
.form input[type="text"], .form >input[type="password"], .form >input[type="email"] {
    box-sizing: border-box;
    align-self: stretch;
    background-color: #fff;
    color: #3c3c3b;
    border-radius: 2em;
    font-size: .8em;
    font-weight: 500;
    padding: .8em 2em;
    line-height: 1.2em;
}
.form input::placeholder {
    font-size: 1em;
    color: #3c3c3b;
    font-weight: 700;
}
.form input[type="checkbox"] {
    display: inline-block;
    width:1em;
    height:1em;
}
.rulesCheckboxContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1em;
}
.rulesCheckboxContainer  fieldset {
    display: flex;
    align-items: center;
    border: none;
    background-color: #fff;
    border-radius: 2em;
    padding: .2em 1em;
    gap: .5em;
    font-weight: 700;
	font-size: 1em;
}
.rulesCheckboxContainer  fieldset label {
	font-size: .6em;
}
.rulesCheckboxContainer  fieldset a{
    color:#3c3c3b;
    text-decoration: none;
}
.signUpButton{
    background: #3c3c3b;
    color: #fff;
    font-size: 1.2em;
    padding: .5em 1.5em;
    border-radius: 2em;
    box-shadow: none;
    font-weight: 500;
    margin-top: 1em;
}
.returnButton{
    font-size: 1.2em;
    padding: .5em 1.5em;
    font-weight: 500;
    border-radius: 2em;
    box-shadow: none;
    margin-top: 1em;
}
.passwordCheckContainer > h2{
    color: #fff;
    font-weight: normal;
    text-decoration: underline;
    font-size: 1.2em;
    margin: 0;
}
.passwordCheckContainer  ul{
    padding-left: 20px;
}
.passwordCheckContainer li > p{
    font-weight: normal;
    color: #fff;
    margin:0;
    font-size: 1em;
}
.error {
    font-weight: 700;
    color:red;
}
.backgroundContainer {
	background: var(--primary);
	width: 100vw;
	position: absolute;
	height: 100vh;
	z-index: 0;
}

.backgroundLogo {
	position: fixed;
	z-index: 0;
}

.logo {
	z-index: 2;
	width: 25%;
}