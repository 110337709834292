.container {
	overflow: hidden auto;
	scrollbar-width: none;
	box-sizing: content-box;
	padding: 0 32px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.container::-webkit-scrollbar {
	display: none;
}

.imageContainer {
	width: 100vw;
	display: flex;
	justify-content: center;
	margin: 30px 0;
}

.imageContainer > img {
	width: 80vw;
}
