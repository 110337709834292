.container {
	height: 80vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	font-size: 18px;
}

.backgroundContainer {
	background: var(--primary);
	width: 100vw;
	height: 100vh;
}

.backgroundLogo {
	position: fixed;
	z-index: 0;
}
