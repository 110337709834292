
.heading {
    display: block;
    font-size: 3rem;
    margin-bottom: 0;
    position: relative;
    color: var(--font-color);
    margin:0;
}
.heading::after {
    content: "";
    display: block;
    width: 100%;
    height: 0.12em;
    bottom: 0.2em;
    left:0;
    position: absolute;
    background-color: var(--primary);
    clip-path: polygon(0 50%, 60% 100%, 100% 60%, 75% 0);
}