.container {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.container > h1 {
	color: #3c3c3b;
	user-select: none;
}

.orderContainer {
	width: 75%;
	background: #3c3c3b;
	color: white;
	border-radius: 1em;
	box-shadow: 3px 3px 5px #bdbdbd;
	padding: 10px 30px;
	margin: 10px 0;
	display: grid;
	grid-template-columns: 1fr 16px 1fr;
	max-width: 700px;
}

.divider {
	margin: auto;
	width: 2px;
	background: #d1d1d1;
}
