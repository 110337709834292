.popupContainer > div:first-child,
.popupContainer > div:last-child > div {
	background: #3c3c3b;
}

.popupContainer > div:first-child > div {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	background: #3c3c3b;
	color: white;
	margin: 4px;
	padding: 1em 1em
}

.selectButton {
	color: white;
	box-shadow: none;
	background-color: var(--primary);
	padding: 8px;
	font-size: 1.2em;
	font-weight: bold;
}
