.container {
	height: calc(var(--full-mobile-page-height) - 64px);
	overflow: hidden;
	color: #fff;
	display: flex;
	flex-direction: column;
	align-items: center;
	background: #3c3c3b;
	padding: 32px;
}

.container > input {
	margin-bottom: 20px;
	width: 65%;
	background: white url("assets/mobile/ProductsPage/searchIcon.svg") no-repeat 13px center;
	background-size: 20px;
	border: 1px solid #c8c8c8;
	box-shadow: unset;
	padding: 0.7em 3em;
	color: #3c3c3b;
}

.container > input::placeholder {
	color: #3c3c3b;
	font-weight: 600;
	text-align: center;
}

.container > input:focus {
	outline: none;
	border-color: #929292;
}

.container > h1 {
	margin-bottom: 8px;
}

.listContainer {
	height: calc(100% - var(--navbar-height) * 2);
	overflow: auto;
	width: 100%;
	display: flex;
	flex-direction: column;
}

.listContainer div[name="category"],
.listContainer div[name="categoryTitle"] {
	border-bottom: 1px solid #c8c8c8;
	padding: 10px;
	text-align: center;
	font-size: 1.2rem;
	transition: 0.5s;
}

.listContainer div[name="categoryTitle"] {
	cursor: pointer;
	border-top: 1px solid black;
	display: flex;
	align-items: center;
	justify-content: space-between;
	text-align: left;
	transition: 0.5s;
}

.listContainer div[name="category"]:last-child {
	border-bottom: unset;
}
