.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: safe center;
	height: calc(99vh - var(--navbar-height));
}

.widgetContainer {
	display: flex;
	flex-direction: row;
	gap:4em;
	margin-top:6em;
}
.subtitle{
	margin:0;
	font-weight: 500;
}
.widget{
	position: relative;
	padding-top: 3em;
	box-sizing: border-box;
	background-color: #f2f2f2;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	border-radius: 3.5em;
	width: 35em;
}
.widget > img {
	position: absolute;
	height:6em;
	aspect-ratio: 1;
	filter: invert(0.8);
	top: -4em;
	left: calc(50% - 3em);
}
.widgetContent { 
	padding:0 3em;
	text-align: center;
}
.widgetContent > p {
	font-size: 2em;
	margin: 0;
	margin-bottom: 1.5em;
}
.widget > .widgetButton{
	margin-top: 1.5em;
	box-sizing: border-box;
	width: 100%;
	font-size: 2em;
	text-align: center;
	border-radius: 2em;
	padding: 1.5em 1em;
	background-color: var(--primary);
	cursor: pointer;
}
.background{
	top: var(--navbar-height);
	width:100vw;
	z-index: 0;
	position: fixed;
}
.background > svg{
	position: absolute;
}
@media (max-width: 1400px) {
	.container{
        font-size: 0.6em;
    }
} 

.widget .input input {
	 background: none;
	 font-size: 2em;
	 padding:0;
	 text-align: center;
	 border:none;
}
.widget .input input:focus {
	outline: none;
}
.widget .input input::placeholder {
	font-weight: 700;
	text-align: center;
	color: #3c3c3b;
}

.widget .input {
	position: relative;
}
.widget .input::after {
    content: "";
    display: block;
    width: 100%;
    height: 0.5em;
    bottom: -.5em;
    left:0;
    position: absolute;
    background-color:var(--primary);
    clip-path: polygon(0 50%, 60% 100%, 100% 60%, 75% 0);
}