.container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: auto;   
    height:100%;
    text-align: center;
}
.container h2 {
    font-size: 2vh;
}

.svg {
    width: 10vw;
    height: 10vw;
}

.info {
    margin-top: 3vh;
    margin-bottom: 3vh;
}